import { Download, Rule, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu, MenuItem,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { getChip } from "../../../data/hr03Status";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  forceReload as forceReloadGrid,
  setFilter,
} from "../../../ducks/hr03MgtList";
import Hr03AccountView from "../hr03/Hr03AccountView";
import ReportCSV from "./exportCSV";
import ReportExcel from "./exportExcel";
import Hr03MgtSearchForm, { validate } from "./Hr03MgtSearchForm";
import Hr03VerifyView from "./Hr03VerifyView";
import fileDownload from "js-file-download";
import api from "../../../apis";

export default function Hr03MgtListPage() {
  const verify_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03 ?? []
  );
  const dispatch = useDispatch();

  // let resolved = useResolvedPath("/admin/request-mgt");
  // let isAdminPath = useMatch({ path: resolved.pathname, end: false });

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.hr03Mgt.data);
  const total = useSelector((state) => state.hr03Mgt.meta.total);
  const loading = useSelector((state) => state.hr03Mgt.meta.loading);
  const forceReload = useSelector((state) => state.hr03Mgt.meta.forceReload);
  const page = useSelector((state) => state.hr03Mgt.meta.page);
  const pageSize = useSelector((state) => state.hr03Mgt.meta.pageSize);
  const filter = useSelector((state) => state.hr03Mgt.meta.filter);

  const hr03OrgName = useSelector((state) => state.auth.user.hr03Org?.ORG);

  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    // const activeFilter = hr03Status
    //   ? { ...filter, status: hr03Status }
    //   : filter;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    if (payload?.toast) {
      SetToastInfo(payload.toast);
      if (payload.toast.type !== "error") {
        dispatch(forceReloadGrid());
      }
    }
    setOpenModal(false);
  };

  const renderAction = ({ row }) => {
    if (
      row.status === "sent_to_bh" &&
      verify_account_HR03.includes("confirm")
    ) {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            size="small"
            sx={{ boxShadow: 1 }}
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyView
                  handleCancel={handleCloseModal}
                  itemId={row.id}
                  caseId={row.case_id}
                />
              );
            }}
          >
            <Rule color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      verify_account_HR03.includes("view") && (
        <Tooltip title="ดูข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03AccountView
                  handleCancel={handleCloseModal}
                  itemId={row.id}
                />
              );
            }}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      )
    );
  };

  const renderTitle = () => {
    // switch (hr03Status) {
    //   case "sent_to_bh":
    //     return (
    //       <Stack direction="row" spacing={1}>
    //         <Typography variant="h5">ตรวจสอบข้อมูล HR03</Typography>
    //         {getChip(hr03Status)}
    //       </Stack>
    //     );
    //   default:
    //     return <Typography variant="h5">รายการ HR03</Typography>;
    // }
    return <Typography variant="h5">ตรวจสอบข้อมูล HR03</Typography>;
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Stack
            spacing={0.5}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            {renderAction(params)}
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        // const onChipClick =
        //   params.row.status === "sent_to_bh"
        //     ? () => {
        //         handleOpenModal(
        //           <Hr03VerifyView
        //             handleCancel={handleCloseModal}
        //             itemId={params.row.id}
        //             caseId={params.row.case_id}
        //           />
        //         );
        //       }
        //     : undefined;

        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, {
              size: "small",
              // onClick: onChipClick,
            })}{" "}
            {params.row.status === "new" && (
              <Chip
                label="กรุณายืนยัน"
                color="warning"
                size="small"
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "hr_type",
      headerName: "ประเภท",
      sortable: false,
      width: 80,
      valueFormatter: ({ value }) => {
        return value === 1 ? "HR03-1" : "HR03-2";
      },
    },
    {
      field: "tpo_case_id",
      headerName: "caseId",
      sortable: false,
      width: 100,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-สกุล",
      sortable: false,
      minWidth: 120,
      maxWidth: 250,
      valueGetter: ({ row }) => {
        return `${row.name} ${row.surname}`;
        // return params.row.bank?.short_name;
      },
    },
    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: false,
      width: 80,
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 150,
      maxWidth: 180,
    },
    {
      field: "pol_name",
      headerName: "ชื่อ พงส.",
      sortable: false,
      width: 150,
    },
    {
      field: "pol_policestation",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        return value.ORG_ABBR;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  const onSearchSubmit = (data) => {
    let filter = { ...data };
    if (data.policestation_org_code) {
      const { org_type, org_code } = filter.policestation_org_code;
      filter = _.omit(filter, ["policestation_org_code"]);
      switch (org_type) {
        case "BH":
          filter.bh_code = org_code;
          break;
        case "BK":
          filter.bk_code = org_code;
          break;
        default:
          filter.kk_code = org_code;
      }
    }
    dispatch(setFilter(filter));
  };

  useEffect(() => {
    dispatch(setFilter({ status: "sent_to_bh" }));
  }, [dispatch]);

  // delete record confirmation
  // const [cfDel, setCfDel] = useState(false);
  // const handleCfDelClose = () => setCfDel(false);
  // const handleCfDelOk = () => {
  //   return api
  //     .delete(`/api/nominees/${cfDel}`)
  //     .then(() => {
  //       dispatch(forceReloadGrid());
  //     })
  //     .catch(() => {
  //       SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
  //     });
  // };

  // if (!isValidStatus) {
  //   return <Navigate to="/" />;
  // }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      {/* <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ บัญชีม้า(ID: {cfDel})
      </ConfirmDialog> */}
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {renderTitle()}
        {hr03OrgName && (
          <Typography variant="subtitle1">{hr03OrgName}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          {verify_account_HR03.includes("search") && (
            <Form
              validate={validate}
              onSubmit={onSearchSubmit}
              component={Hr03MgtSearchForm}
              loading={loading}
              onReset={() => dispatch(setFilter({ status: "sent_to_bh" }))}
              showStatusField
              initialValues={{ status: "sent_to_bh" }}
            />
          )}
          {verify_account_HR03.includes("download") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: 2,
              }}
            >
              <LoadingButton
                id="export-button-excel"
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                // onClick={() => handleDownload("/api/", setPdfDownloading)}
                aria-controls={openDowload ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={handleClickDowload}
              >
                ดาวน์โหลด
              </LoadingButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElDowload}
                open={openDowload}
                onClose={handleCloseDownload}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={async () => {
                    // ReportExcel(rows);
                    setIsLoadingExcel(true);
                    handleCloseDownload();
                    try {
                      const res = await api.get(
                        `/api/mgt/hr_reports/list`,
                        {
                          responseType: "blob",
                          params:{
                            page: page,
                            page_size: pageSize,
                            export: "excel",
                            status:"sent_to_bh",
                            ...filter,
                          }
                        }
                      );

                      const contentDisposition =
                        res.headers["content-disposition"];
                      const filename = /filename=.+/.exec(contentDisposition)
                        ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                        : "download.xlsx";

                      fileDownload(res.data, filename);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingExcel(false);
                    }
                  }}
                >
                  Excel
                </MenuItem>
                <MenuItem
                  onClick={async() => {
                    // ReportCSV(rows);
                    setIsLoadingExcel(true);
                    handleCloseDownload();
                    try {
                      const res = await api.get(
                        "/api/mgt/hr_reports/list",
                        {
                          responseType: "blob",
                          params:{
                            page: page,
                            page_size: pageSize,
                            export: "csv",
                            status:"sent_to_bh",
                            ...filter,
                          }
                        }
                      );

                      const contentDisposition =
                        res.headers["content-disposition"];
                      const filename = /filename=.+/.exec(contentDisposition)
                        ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                        : "download.csv";

                      fileDownload(res.data, filename);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingExcel(false);
                    }
                  }}
                >
                  CSV
                </MenuItem>
              </Menu>
            </Box>
          )}

          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
          />
        </Paper>
      </Grid>
    </>
  );
}
