import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import api from "../../../../apis";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import toast from "react-hot-toast";
import thaiIdValidator from "../../../../helpers/ThaiIdValidator";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";

export default function UserForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  setStatus,
  setDataPermission,
  setCardId,
  itemptm,
}) {
  const qState = useSelector((state) => state.auth.user.permissions);
  const [isMerge, setIsMerge] = useState(false);
  const handleMergeClose = () => setIsMerge(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [reFetch, setReFetch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  // console.log(values);
  const { data: roles } = useQuery(
    ["roles"],
    () => api.get(`/api/list/roles`).then((res) => res.data)
    // { staleTime: 60 * 1000 }
  );

  const [selectRoles, setSelectroles] = React.useState([]);
  const [statusCheck, setStatusCheck] = React.useState("1");

  useEffect(() => {
    if (initialValues) {
      setSelectroles(initialValues.roles);
      setStatusCheck(initialValues.status);
    }
  }, [initialValues]);

  useEffect(() => {
    setCardId && setCardId(values.cardId);
  }, [setCardId, values]);

  const processMerge = (item) => {
    form.batch(() => {
      form.change("status_code", item.status_code);
      form.change("status", item.status);
      form.change("rank", item.rank_abbr);
      form.change("level_code", item.level_code);
      form.change("job_title", item.level_name);
      form.change("level_abbr", item.level_abbr);
      form.change("fname", item.name);
      form.change("lname", item.sname);
      form.change("birthday", item.born_date);
      form.change("sex", item.sex);
      form.change("policestation_org_code", item.org_code);
      form.change("agency_under", item.org_code);
      setReFetch(true);
      form.change("org_desc", item.org_desc);
      form.change("pos_org", item.pos_org);
      form.change("card_id", item.card_id);
      form.change("kk_code", item.kk_code);
      form.change("bk_code", item.bk_code);
      form.change("bh_code", item.bh_code);
    });
  };
  useEffect(() => {
    if (!_.isEmpty(itemptm) && itemptm) {
      form.change("temp", itemptm);
      setIsMerge(true);
      setReFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemptm]);
  return (
    <form onSubmit={handleSubmit} noValidate>
      <ConfirmDialog
        open={isMerge}
        onClose={handleMergeClose}
        cfBtnProps={{ label: "ยืนยัน", color: "success" }}
        onConfirm={() => processMerge(itemptm)}
        title="ตรวจสอบข้อมูลรายละเอียดพนักงาน จากข้อมูลกำลังพล"
        maxWidth="lg"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={6}>
            <FieldInput
              name="temp.rank_abbr"
              label="ยศ"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.level_name"
              label="ตำแหน่ง"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.name"
              label="ชื่อ"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.sname"
              label="สกุล"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.card_id"
              label="เลขบัตรประชาชน"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.org_desc"
              label="หน่วยงานที่สังกัด"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInput
              name="temp.status"
              label="สถานะ"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
            />
          </Grid>
        </Grid>
        <Typography variant="h6" color="error.main">
          ***หากยืนยันอัพเดทข้อมูลแล้ว อาจส่งผลให้ข้อมูลรายการ Case
          ของพนักงานท่านนี้ ไม่สามารถมองเห็นได้อีก
        </Typography>
      </ConfirmDialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <FieldInput
            name="email"
            label="Email"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
          <Typography variant="caption" color="gray">
            รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th
            เท่านั้น
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="password"
            label="รหัสผ่าน"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            helperText={
              "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="confirm_password"
            label="ยืนยันรหัสผ่าน"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            helperText={
              "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="rank"
            label="ยศ"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="pol_tel"
            label="โทรศัพท์"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="สกุล"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="job_title"
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAsyncAutoComplete
            name="agency_under"
            label="หน่วยงานที่สังกัด"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/policestations"
            itemFormat={(row) => {
              return {
                value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                label: row.ORG_ABBR,
              };
            }}
            reFetch={reFetch}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="cardId"
            label="เลขบัตรประชาชน"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <div className="z-50">
              <Select
                value={selectRoles}
                isMulti
                onChange={(e) => setSelectroles(e)}
                options={roles}
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.id}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="เลือกสิทธิ์การใช้งาน"
                styles={{
                  // menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    width: "100%",
                    height: 50,
                    minHeight: 40,
                  }),
                }}
                menuContainerStyle={{ zIndex: 999 }}
                maxMenuHeight={190}
              />
            </div>
            {/* <InputLabel id="demo-multiple-checkbox-label">
              สิทธิ์การใช้งาน
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={permission}
              onChange={handleChange}
              input={<OutlinedInput label="" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {rows?.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox checked={permission.indexOf(value.id) > -1} />
                  <ListItemText primary={value.name} />
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="z-0">
            <FieldAsyncAutoComplete
              name="agency_supervise"
              label="หน่วยงานที่ดูแล"
              controlProp={{ fullWidth: true }}
              basePath="/api/policestations"
              itemFormat={(row) => {
                return {
                  value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                  label: row.ORG_ABBR,
                };
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={statusCheck}
              onChange={(e) => {
                setStatusCheck(e.target.value);
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="ใช้งาน" />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="ไม่ใช้งาน"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
              {!qState?.setting_user?.includes("edit") && (
                <p className="text-red-400 my-2">คุณไม่มีสิทธิ์แก้ไข</p>
              )}
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  // pristine={pristine}
                  onClick={() => {
                    // console.log(selectRoles)
                    setStatus(statusCheck);
                    setDataPermission(selectRoles);
                  }}
                  disabled={!qState?.setting_user?.includes("edit")}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }

                    navigate("/settinguser");
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validateRules = () => ({
  rank: Joi.string()
    .max(255)
    .messages({ "string.base": `กรุณาระบุยศ`, "any.required": "กรุณาระบุยศ" })
    .required(),
  cardId: Joi.string()
  .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
  .required()
  .messages({
    "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
  }),
  // laserCode: Joi.string().required(),
  fname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .messages({
      "string.pattern.base": "โทรศัพท์ต้องมี 10 หลัก",
      "any.required": "กรุณาระบุเบอร์โทรศัพท์",
    })
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "รูปแบบอีเมลไม่ถูกต้อง",
      "any.required": "กรุณาระบุอีเมล",
    })
    .required(),
  // job_title: Joi.string().max(255),
  agency_under: Joi.any()
    .messages({
      "any.required": "กรุณาระบุหน่วยงานที่สังกัด",
    })
    .required(),
  agency: Joi.any(),
  // agency_supervise: Joi.any().messages({
  //   'string.base': `กรุณาระบุหน่วยงานที่ดูแล`,
  //   'any.required': 'กรุณาระบุหน่วยงานที่ดูแล'
  // }).required(),
  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัส",
    })
    .required(),
  confirm_password: Joi.any()
    .valid(Joi.ref("password"))
    .messages({
      "any.only": "รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ต้องตรงกันเท่านั้น",
      "any.required": "กรุณาระบุยืนยันรหัสผ่าน",
    })
    .required(),
  // status: Joi.bool(),
});

export const validateRulesEdit = () => ({
  rank: Joi.string()
    .max(255)
    .messages({ "string.base": `กรุณาระบุยศ`, "any.required": "กรุณาระบุยศ" })
    .required(),
  cardId: Joi.string()
  .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
  .required()
  .messages({
    "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
  }),
  // laserCode: Joi.string().required(),
  fname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .messages({
      "string.pattern.base": "โทรศัพท์ต้องมี 10 หลัก",
      "any.required": "กรุณาระบุเบอร์โทรศัพท์",
    })
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "รูปแบบอีเมลไม่ถูกต้อง",
      "any.required": "กรุณาระบุอีเมล",
    })
    .required(),
  // job_title: Joi.string().max(255),
  agency_under: Joi.any()
    .messages({
      "any.required": "กรุณาระบุหน่วยงานที่สังกัด",
    })
    .required(),
  agency: Joi.any(),

  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัส",
    }),
  confirm_password: Joi.any()
    .valid(Joi.ref("password"))
    .when("password", {
      is: Joi.any().valid(null, ""),
      then: Joi.optional().allow(null, ""),
      otherwise: Joi.required(),
    })
    .messages({
      "any.only": "รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ต้องตรงกันเท่านั้น",
      "any.required": "กรุณาระบุยืนยันรหัสผ่าน",
    }),
  // status: Joi.bool(),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = () => Joi.object(validateRules());
const schemaEdit = () => Joi.object(validateRulesEdit());

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateEdit = (values, props) => {
  const errors = {};
  const vResult = schemaEdit().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const mapToInit = (item) => {
  return item;
};
