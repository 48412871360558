import {
  Alert,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Tooltip,
  Chip,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionForceReload,
  permissionClearResult,
  permissionSetFilter,
} from "../../../../ducks/setting";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Refresh, Edit, Delete, List } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import PermissionSearchForm, { validate } from "./PermissionSearchForm";
import { Form } from "react-final-form";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import api from "../../../../apis";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import { format, isValid, parseISO } from "date-fns";
import { useQuery } from "react-query";
import _ from "lodash";

export default function SettingPermission() {
  const qState = useSelector((state) => state.auth.user.permissions);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: reqStatus } = useParams();

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(permissionClearResult());
      dispatch(permissionSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    if (payload?.type === "error") {
      SetToastInfo(payload);
    } else {
      dispatch(permissionForceReload());
    }
    setOpenModal(false);
  };

  // const fetchIdRef = React.useRef(0);
  // const rows = useSelector((state) => state.setting.permission.data);
  // const total = useSelector((state) => state.setting.metaPermission.total);
  const loading = useSelector((state) => state.setting.metaPermission.loading);
  // const forceReload = useSelector(
  //   (state) => state.setting.metaPermission.forceReload
  // );
  // const page = useSelector((state) => state.setting.metaPermission.page);
  // const pageSize = useSelector(
  //   (state) => state.setting.metaPermission.pageSize
  // );
  const filter = useSelector((state) => state.setting.metaPermission.filter);
  console.log({ filter });

  const {
    isLoading,
    isError,
    data: roles,
    refetch: rolesRefetch,
  } = useQuery(
    ["roles_list"],
    () =>
      api
        .get(
          `/api/roles?enable=${_.get(filter, "status", "")}&search=${_.get(
            filter,
            "search",
            ""
          )}`
        )
        .then((res) => res.data)
    // { staleTime: 60 * 1000 }
  );

  useEffect(() => {
    rolesRefetch();
  }, [filter]);

  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);

  const handleCfDelOk = () => {
    return api
      .delete(`/api/roles/${cfDel}`)
      .then(() => {
        rolesRefetch();
        handleCfDelClose();
        // dispatch(permissionForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  const columns = [
    qState?.setting_permission?.includes("view") ||  qState?.setting_permission?.includes("edit") ||  qState?.setting_permission?.includes("delete") ?

    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {/* <Stack
            spacing={1}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            {renderAction(params)}
            </Stack> */}
            { qState?.setting_permission?.includes("view") ||  qState?.setting_permission?.includes("edit") ?     <Tooltip title="แก้ไข">
              <IconButton
                type="button"
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  navigate(`/permission/edit/${params.id}`);
                }}
              >
                <List />
              </IconButton>
            </Tooltip> : ""}
        
           {qState?.setting_permission?.includes("delete") && <Tooltip title="ลบ">
              <IconButton
                type="button"
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  setCfDel(params.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip> }
            
          </>
        );
      },
    } : null,
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 70,
    },
    {
      field: "enable",
      headerName: "สถานะการใช้งาน",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.enable === 0 ? (
                <Chip
                  label="ปิดใช้งาน"
                  color="error"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              ) : (
                <Chip
                  label="ใช้งาน"
                  color="success"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "สิทธิ์การใช้งาน",
      sortable: true,
      width: 200,
    },
    {
      field: "description",
      headerName: "รายละเอียด",
      sortable: true,
      minWidth: 300,
      maxWidth: 400,
    },
    {
      field: "created_at",
      headerName: "วันที่สร้าง",
      sortable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
    {
      field: "updated_at",
      headerName: "วันที่อัพเดต",
      sortable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
  ].filter(Boolean);

  const onSearchSubmit = (formValues) => {
    dispatch(permissionSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  function compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ(ID: {cfDel})
      </ConfirmDialog>
      <Grid item xs={12}>
        <Typography variant="h5">สิทธิ์การใช้งาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={PermissionSearchForm}
            loading={loading}
            onReset={() => dispatch(permissionSetFilter({}))}
            showStatusField={reqStatus ? false : true}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={_.orderBy(roles, "id", "desc")}
            // rowCount={total}
            // loading={loading}
            // rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            // page={page}
            // pageSize={pageSize}
            paginationMode="server"
            // onPageChange={(page) => dispatch(permissionChangePage(page))}
            // onPageSizeChange={(pageSize) =>
            //   dispatch(permissionChangePageSize(pageSize))
            // }
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(permissionForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
