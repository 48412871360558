import { Search } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import hrStatusSelect from "../../../data/selects/hr03Status";
import validationMsg from "../../../helpers/validationMsg";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

export default function Hr03MgtSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  // const verify_account_HR03_waiting_1 = useSelector(
  //   (state) => state.auth.user.permissions.verify_account_HR03_waiting_1 ?? []
  // );
  // const verify_account_HR03_denied_1 = useSelector(
  //   (state) => state.auth.user.permissions.verify_account_HR03_denied_1 ?? []
  // );
  const verify_account_HR03_waiting_2 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_waiting_2 ?? []
  );
  const verify_account_HR03_denied_2 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_denied_2 ?? []
  );
  const verify_account_HR03_waiting_3 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_waiting_3 ?? []
  );
  const verify_account_HR03_pre_meeting = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_pre_meeting ?? []
  );
  const verify_account_HR03_confirm_3 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_confirm_3 ?? []
  );
  const verify_account_HR03_denied_3 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_denied_3 ?? []
  );
  const verify_account_HR03_waiting_4 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_waiting_4 ?? []
  );
  const verify_account_HR03_HR03 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03_HR03 ?? []
  );

  const statusSelect = hrStatusSelect.filter(
    (item) =>
      // (verify_account_HR03_waiting_1.includes("list") && item.value !== "") || //บก. พิจารณา
      // (verify_account_HR03_denied_1.includes("list") && item.value !== "") || //บก. ปฏิเสธ
      ((verify_account_HR03_waiting_2.includes("list") &&
        item.value !== "sent_to_bh") ||
        (verify_account_HR03_denied_2.includes("list") &&
          item.value !== "bh_reject") ||
        (verify_account_HR03_waiting_3.includes("list") &&
          item.value !== "bh_approve") ||
        (verify_account_HR03_pre_meeting.includes("list") &&
          [
            "rtp_process1",
            "rtp_process2",
            "rtp_process3",
            "rtp_process4",
            "rtp_process3-1",
            "rtp_process3-2",
            "rtp_process3-3",
            "rtp_process3-4",
          ].includes(item.value)) ||
        (verify_account_HR03_confirm_3.includes("list") &&
          item.value !== "rtp_approve") ||
        (verify_account_HR03_denied_3.includes("list") &&
          item.value !== "rtp_reject") ||
        (verify_account_HR03_waiting_4.includes("list") &&
          item.value !== "sent_to_aml") ||
        (verify_account_HR03_HR03.includes("list") && item.value !== "hr03")) &&
      item.value !== "new"
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <FieldAutoComplete
            name="hr_type"
            label="ประเภท"
            options={[
              {
                value: 1,
                label: "HR03-1",
              },
              {
                value: 2,
                label: "HR03-2",
              },
            ]}
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        {showStatusField && (
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={statusSelect}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          {/* {renderPoliceStationField()} */}
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            controlProp={{ fullWidth: true }}
            basePath="/api/policestations"
            itemFormat={(row) => {
              return {
                value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                label: row.ORG_ABBR,
              };
            }}
            inputProps={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            // filter={{ type: "bank" }}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid>
        {/* <Grid item xs={12} xl={4}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            spacing={2}
          >
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Stack>
        </Grid> */}
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // const now = new Date();
  // if (!errors.start_date && values.start_date) {
  //   const startDate = parseISO(values.start_date);
  //   if (isAfter(startDate, now)) {
  //     errors.start_date = "มากกว่าเวลาปัจจุบัน";
  //   }
  // }

  // if (!errors.end_date && values.end_date) {
  //   const endDate = parseISO(values.end_date);
  //   if (isAfter(endDate, now)) {
  //     errors.end_date = "มากกว่าเวลาปัจจุบัน";
  //   }
  // }

  // if (
  //   !errors.start_date &&
  //   !errors.end_date &&
  //   values.start_date &&
  //   values.end_date &&
  //   isAfter(parseISO(values.start_date), parseISO(values.end_date))
  // ) {
  //   errors.start_date = "ไม่ถูกต้อง";
  // }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  hr_type: Joi.number().valid(1, 2).allow(null),
  // policestation_org_code: Joi.object({
  //   org_code: Joi.string()
  //     .pattern(/^\d{5}$/)
  //     .required(),
  //   org_type: Joi.string().uppercase().length(2).required(),
  // }),
  policestation_org_code: Joi.object({
    org_code: Joi.string().pattern(/^\d{5}$/),
    org_type: Joi.string().uppercase().required(),
  }),
  bank_id: Joi.number().allow(null),
  // start_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  // end_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
