import {
  Add,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info,
  PictureAsPdf,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import fileDownload from "js-file-download";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import countryOptions from "../../../data/selects/countries";
import {
  personalClearResult,
  personalForceReload,
  personalSetFilter
} from "../../../ducks/personalList";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import PersonalAddPage from "./PersonalAddPage";
import PersonalEditPage from "./PersonalEditPage";
import PersonalHistory from "./PersonalHistory";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const steps = [
  "New",
  "Contact Person",
  "Checked",
  "Bank",
  "Success",
  "Email Success",
];
export default function PersonalList() {
  const cases = useSelector((state) => state.auth.user.permissions.cases ?? []);
  const params = useParams();
  const caseId = parseInt(params.id);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // add request modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = useCallback(() => {
    setOpenModalHistory(false);
  }, []);

  // const rows = useSelector((state) => state.personalList.personal || []);
  // const total = useSelector((state) => state.personalList.metaPersonal.total);
  const loading = useSelector(
    (state) => state.personalList.metaPersonal.loading
  );
  const forceReload = useSelector(
    (state) => state.personalList.metaPersonal.forceReload
  );
  const [rowsState, setRowsState] = React.useState({
    page: 1,
    pageSize: 5,
  });

  // const filter = useSelector((state) => state.caseRequestList.meta.filter);
  // const requestColumns = useSelector(
  //   (state) => state.personalList.metaPersonal.requestColumns || []
  // );

  const {
    data: personHistory,
    refetch: personHistoryRefresh,
    loading: personHistoryLoading,
  } = useQuery(
    ["personHistory", rowsState],
    () =>
      api
        .get(`/api/cases/${caseId}/persons`, {
          params: {
            page_size: rowsState?.pageSize,
            page: rowsState?.page,
          },
        })
        .then((res) => res.data),
    { staleTime: 60 * 1000 }
  );

  const _columns = [
    ...(cases.includes("edit") //||cases.includes("delete")
      ? [
          {
            field: "action",
            headerName: "จัดการ",
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Stack spacing={1} direction="row">
                  {cases.includes("edit") && (
                    <IconButton
                      type="button"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenModal(
                          <PersonalEditPage
                            personalId={params?.id}
                            handleCancel={handleCloseModal}
                          />
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {/* {cases.includes("delete") && ( */}
                  <IconButton
                    type="button"
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setCfDel(params?.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {/* )} */}
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "fname-lname",
      headerName: "ชื่อ-สกุล",
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography>
              {params.row.fname} {params.row.lname}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "idcard",
      headerName: "เลขบัตรประชาชน/พาสปอร์ต",
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {/* <Typography color="primary">1234567890123</Typography> */}
            <Button
              variant="text"
              onClick={() => {
                handleOpenModalHistory(
                  <PersonalHistory
                    handleCancel={handleCloseModalHistory}
                    data={params?.row}
                  />
                );
              }}
            >
              {params.row.card_id}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "ประเภท",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const list = bankAccountTypes.filter(
          (item) => item.value === params.row.type_id
        );
        const type = list.length > 0 ? list[0] : null;
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography>{type?.label}</Typography>
          </Box>
        );
      },
    },
    {
      field: "nationality",
      headerName: "สัญชาติ",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const list = countryOptions.filter(
          (item) => item.value === params.row.country_code
        );
        const type = list.length > 0 ? list[0] : null;
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography>{type?.label}</Typography>
          </Box>
        );
      },
    },
  ];

  // ฟังก์ชันสำหรับสร้างคอลัมน์ใหม่และเพิ่มเข้าไปในคอลัมน์เดิม
  const generateAndAppendRequestColumns = (existingColumns, personHistory) => {
    if (personHistory?.request_column === undefined) return [];

    const newColumns = personHistory?.request_column?.map((name) => ({
      field: name.toLowerCase(),
      headerName: name,
      sortable: false,
      maxWidth: 250,
      renderCell: (params) => {
        const row = params.row[params.field.toUpperCase()];
        return (
          <Stack alignItems="center">
            <Stack direction="row">({row.total})</Stack>
            <Stack direction="row">
              {row.status === null && <Chip label="ยังไม่ขอ" size="small" />}
              {row.status !== null && getChip(row.status, { size: "small" })}
            </Stack>
          </Stack>
        );
      },
    }));
    return [...existingColumns, ...newColumns];
  };

  const columns = generateAndAppendRequestColumns(_columns, personHistory);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(personalClearResult());
      dispatch(personalSetFilter({}));
    };
  }, [dispatch]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/cases/${caseId}/persons/${cfDel}`)
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบข้อมูลเรียบร้อยแล้ว" });
        dispatch(personalForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handlePageChange = (newPage) => {
    setRowsState((prev) => ({ ...prev, page: newPage }));
  };

  useEffect(() => {
    personHistoryRefresh();
  }, [personHistoryRefresh, rowsState, caseId, forceReload]);

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      id="personal"
    >
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Modal
        open={openModalHistory ? true : false}
        onClose={handleCloseModalHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModalHistory}</Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={5} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Box>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <DeleteIcon /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ ข้อมูลบุคคล(ID: {cfDel})
      </ConfirmDialog>
      <Typography gutterBottom variant="h6">
        ข้อมูลบุคคล
        <Button size="small" onClick={handleOpen} startIcon={<Info />}>
          สถานะ
        </Button>
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {cases.includes("download") && (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdf />}
            loadingPosition="start"
            loading={pdfDownloading}
            disabled={loadingPDF}
            onClick={async () => {
              try {
                setPdfDownloading(true);
                const res = await api.get(`/api/cases/${caseId}/persons`, {
                  responseType: "blob",
                  params: {
                    export: "pdf",
                    page: rowsState?.page,
                    pageSize: rowsState?.pageSize,
                  },
                });
                const contentDisposition = res.headers["content-disposition"];
                const filename = /filename=.+/.exec(contentDisposition)
                  ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                  : "download.pdf";
                fileDownload(res.data, filename);
              } catch (error) {
                console.log(error);
              } finally {
                setPdfDownloading(false);
              }
            }}
          >
            ดาวน์โหลด PDF
          </LoadingButton>
        )}
        {cases.includes("create") && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => {
              handleOpenModal(
                <PersonalAddPage handleCancel={handleCloseModal} />
              );
            }}
          >
            เพิ่มบุคคล
          </Button>
        )}
      </Stack>
      <DataGrid
        disableColumnMenu
        columns={columns}
        rows={personHistory?.rows ?? []}
        loading={personHistoryLoading}
        rowsPerPageOptions={[5, 10, 20]}
        rowCount={personHistory?.total ?? 0}
        pagination
        paginationMode="server"
        page={rowsState.page - 1}
        pageSize={rowsState.pageSize}
        onPageChange={(newPage) => handlePageChange(newPage + 1)}
        onPageSizeChange={(newPageSize) =>
          setRowsState((prev) => ({ ...prev, page: 1, pageSize: newPageSize }))
        }
        autoHeight
        getRowHeight={() => 80}
      />
    </Paper>
  );
}
