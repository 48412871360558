import { Mail } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldsignatureFileDropZone from "../../../form-fields/FieldsignatureFileDropZone";
import SignaturePreview from "../../requests/request-forms/SignaturePreview";
import ConfirmCfrRequest from "./ConfirmCfrRequest";
import thaiIdValidator from "../../../../helpers/ThaiIdValidator";

export default function RequestNewForm({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Snackbar
          open={toastInfo ? true : false}
          autoHideDuration={6000}
          onClose={handleToastInfoClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {toastInfo?.type && (
            <Alert
              onClose={handleToastInfoClose}
              severity={toastInfo?.type || "warning"}
              sx={{ width: "100%" }}
            >
              {toastInfo?.message || ""}
            </Alert>
          )}
        </Snackbar>
        <Grid item ml={2} mt={2} justifyContent="rigth">
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              <ConfirmCfrRequest readOnly={readOnly} hiddenUserForm={true} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider textAlign="center" sx={{ mt: 2 }}>
                ข้อมูลพนักงานสอบสวน
              </Divider>
            </Grid>
            <Grid item xs={12} md={2}>
              <FieldInput
                name="rank"
                label="ยศ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="fname"
                label="ชื่อ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="lname"
                label="สกุล"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="job_title"
                label="ตำแหน่ง"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldAsyncAutoComplete
                name="policestation_org_code"
                label="หน่วยงาน"
                required
                controlProp={{ fullWidth: true }}
                readOnly
                basePath="/api/policestations"
                // itemFilter={(row) =>
                //   !row.ORG_CODE?.startsWith("0") &&
                //   !row.ORG_CODE?.startsWith("1")
                // }
                itemFormat={(row) => {
                  return { value: row.ORG_CODE, label: row.ORG_ABBR };
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="email"
                label="email"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="pol_tel"
                label="โทรศัพท์"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <FieldInput
                name="address"
                label="ที่อยู่"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
              />
            </Grid>
            <Grid container item xs={12} justifyContent={"center"}>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">ลายเซ็น</Typography>
                {readOnly ? (
                  <SignaturePreview name="profile_signature" />
                ) : (
                  <FieldsignatureFileDropZone
                    name="profile_signature"
                    accept="image/jpeg, image/png, image/gif"
                    placeholder={
                      <>
                        Drag 'n' drop some files here, or click to select files{" "}
                        <br />
                        (*.jpg,*.gif,*.png) only
                      </>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} margin={3}>
            <Divider textAlign="center"></Divider>
          </Grid>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              {!readOnly && (
                <SubmitBtn
                  variant="contained"
                  startIcon={<Mail />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  สร้างคำขอ
                </SubmitBtn>
              )}
              <Button
                type="button"
                variant="outlined"
                disabled={submitting}
                onClick={() => {
                  navigate(`/cases/${caseId}/mgt`);
                }}
              >
                {readOnly ? "ปิด" : "ยกเลิก"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export const validateRules = {
  bank_case_id: Joi.any()
    .messages({
      "string.empty": "กรุณาระบุธนาคาร",
      "any.required": "กรุณาระบุธนาคาร",
    })
    .required(),
  bank_id: Joi.any()
    .messages({
      "string.empty": "กรุณาระบุบัญชีธนาคาร",
      "any.required": "กรุณาระบุบัญชีธนาคาร",
    })
    .required(),
  is_foreigner: Joi.boolean().allow(null),
  victim_card_id: Joi.when("is_foreigner", {
    is: Joi.valid(true).required(),
    then: Joi.string().pattern(/^[0-9A-Z]{1,30}$/),
    otherwise: Joi.string()
      .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
      .required()
      .messages({
        "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
      }),
  })
    .required()
    .messages({
      "any.required": "กรุณาระบุเลขบัตร",
    }),

  nationality: Joi.when("is_foreigner", {
    is: true, // ตรวจสอบว่า is_foreigner เป็น true
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุสัญชาติ", // ข้อความเมื่อ nationality จำเป็นต้องกรอก
    }),
    otherwise: Joi.string().optional(), // ถ้าไม่ใช่ชาวต่างชาติ ไม่จำเป็นต้องกรอก
  }),

  noH: Joi.string().required().messages({
    "string.empty": "กรุณาระบุเลขหนังสือ",
    "any.required": "กรุณาระบุเลขหนังสือ",
  }),

  dateH: Joi.date().required().messages({
    "string.empty": "กรุณาลงวันที่",
    "any.required": "กรุณาลงวันที่",
  }),

  rank: Joi.string()
    .max(255)
    .messages({
      "string.empty": "กรุณาระบุยศ",
      "any.required": "กรุณาระบุยศ",
    })
    .required(),
  fname: Joi.string()
    .max(255)
    .messages({
      "string.empty": "กรุณาระบุชื่อ",
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "string.empty": "กรุณาระบุนามสกุล",
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  job_title: Joi.string()
    .max(255)
    .messages({
      "string.empty": "กรุณาระบุตำแหน่ง",
      "any.required": "กรุณาระบุตำแหน่ง",
    })
    .required(),
  email: Joi.string()
    .messages({
      "string.empty": "กรุณาระบุอีเมล",
      "any.required": "กรุณาระบุอีเมล",
    })
    .required(),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .messages({
      "string.empty": "กรุณาระบุหน่วยงาน",
      "any.required": "กรุณาระบุหน่วยงาน",
    })
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .messages({
      "string.empty": "กรุณาระบุเบอร์โทร",
      "any.required": "กรุณาระบุเบอร์โทร",
    })
    .required(),
  address: Joi.string()
    .messages({
      "string.empty": "กรุณาระบุที่อยู่",
      "any.required": "กรุณาระบุที่อยู่",
    })
    .required(),
  profile_signature: Joi.any().messages({
    "string.empty": "กรุณาอัพโหลดลายเซ็น",
    "any.required": "กรุณาอัพโหลดลายเซ็น",
  }),
};

const schema = Joi.object(validateRules);

export const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};
