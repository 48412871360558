import { Cancel, CheckCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../apis";
import { getChip } from "../../../data/hr03Status";
import validationMsg from "../../../helpers/validationMsg";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import DialogDescForm from "../../dialogs/DescFormDialog";
import Hr03Stepper from "../../templates/Hr03Stepper";
import Hr03AccountForm, { validateRules } from "../hr03/Hr03AccountForm";
import PredefineRejectDesc from "./PredefineRejectDesc";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function Hr03VerifyView({ itemId, handleCancel }) {
  const verify_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.verify_account_HR03 ?? []
  );
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: item,
  } = useQuery(
    ["hr03", itemId],
    () => api.get(`/api/hr_reports/${itemId}`).then((res) => res.data),
    { staleTime: 60 * 1000 }
  );

  const [submitting, setSubmitting] = useState(false);
  const [cfCf, setCfCf] = useState(false);
  const handleCfCfClose = () => setCfCf(false);
  const handleConfirm = () => {
    setSubmitting(true);
    api
      .put(`/api/hr_reports/${itemId}/bh_approve`)
      .then(() => {
        handleCancel({ toast: { type: "success", message: "สำเร็จ" } });
      })
      .then(() => {
        queryClient.invalidateQueries(["hr03", itemId]);
      })
      .catch(({ response }) => {
        handleCancel({ toast: { type: "error", message: "เกิดข้อผิดพลาด" } });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // reject confirmation
  const [cfReject, setCfReject] = useState(false);
  const handleCfRejectClose = () => setCfReject(false);
  const handleReject = (formValues) => {
    return api
      .put(`/api/hr_reports/${itemId}/bh_reject`, formValues)
      .then(() => {
        handleCfRejectClose();
        handleCancel({ toast: { type: "success", message: "สำเร็จ" } });
      })
      .then(() => {
        queryClient.invalidateQueries(["hr03", itemId]);
      })
      .catch((e) => {
        console.log(e);
        handleCfRejectClose();
        handleCancel({ toast: { type: "error", message: "เกิดข้อผิดพลาด" } });
      })
      .finally(() => {});
  };

  const renderContent = () => {
    if (isLoading)
      return (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      );

    if (isError)
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
        </Alert>
      );

    return (
      <>
        <Form
          // validate={validate}
          readOnly
          onSubmit={() => undefined}
          component={Hr03AccountForm}
          initialValues={mapToInit(item)}
        />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item md={12} lg={4}>
            <LoadingButton
              type="button"
              variant="contained"
              startIcon={<CheckCircle />}
              onClick={() => setCfCf(true)}
              loading={submitting}
              loadingPosition="start"
            >
              ตรวจแล้วถูกต้อง
            </LoadingButton>
          </Grid>
          <Grid item md={12} lg={8}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {verify_account_HR03.includes("denied") && (
                <LoadingButton
                  type="button"
                  variant="contained"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => setCfReject(true)}
                  loading={submitting}
                  loadingPosition="start"
                >
                  ปฏิเสธ
                </LoadingButton>
              )}

              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  handleCancel();
                }}
                disabled={submitting}
              >
                ปิด
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  };

  const modalStyle = {
    ...masterModalStyle,
    height: item ? "100%" : undefined,
    width: item ? "80%" : undefined,
  };

  return (
    <Box sx={modalStyle}>
      {verify_account_HR03.includes("denied") && (
        <Form
          validate={validateReject}
          onSubmit={handleReject}
          component={DialogDescForm}
          open={cfReject}
          onClose={handleCfRejectClose}
          cfBtnProps={{ label: "ยืนยัน", color: "error" }}
          title="ยืนยันการปฏิเสธ"
          content={
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">
                โปรดอธิบายเหตุผลการปฎิเสธนี้เพื่อส่งกลับให้ พงส.
                เจ้าของข้อมูลแก้ไข
              </Typography>
              <PredefineRejectDesc />
            </Stack>
          }
          fieldProps={{
            name: "status_note",
            label: "เหตุผล",
            inputProps: {
              multiline: true,
              maxRows: 6,
              minRows: 3,
              autoFocus: true,
            },
          }}
        />
      )}
      <ConfirmDialog
        open={cfCf ? true : false}
        onClose={handleCfCfClose}
        cfBtnProps={{
          label: "ยืนยัน",
          color: "primary",
          startIcon: <CheckCircle />,
        }}
        onConfirm={handleConfirm}
        title="ยืนยันส่งข้อมูล"
      >
        ข้อมูลนี้จะถูกส่งไปยัง ตร. เพื่อพิจารณาต่อไป
      </ConfirmDialog>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="div">
          ตรวจสอบบัญชี HR03 (ID: {itemId})
        </Typography>
        {!isLoading && item?.status && getChip(item.status)}
      </Stack>
      <Paper sx={{ p: 2, mb: 3 }}>
        {item?.status && (
          <Hr03Stepper status={item.status} note={item.status_note} />
        )}
      </Paper>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        {renderContent()}
      </Paper>
    </Box>
  );
}

function mapToInit(item) {
  return _.pick(item, Object.keys(validateRules()));
}

const schema = Joi.object({
  status_note: Joi.string().required(),
}).error(validationMsg());

const validateReject = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};
