import _ from "lodash";
// import api from "../apis";
import LocalStorageService from "../helpers/LocalStorageService";
// import { authApi, setAuthorizationToken } from "../apis/apigw";
import mainAxios, { setAuthorizationToken } from "../apis";
import { setAuthorizationToken as setAuthorizationToken2 } from "../apis/apigw";
import { authFetch } from "./auth";

export const APP_MARK_LOADING = "verifyOTP/APP_MARK_LOADING";
export const APP_INIT_SUCCESS = "verifyOTP/APP_INIT_SUCCESS";
export const APP_INIT_FAIL = "verifyOTP/APP_INIT_FAIL";
export const APP_MA = "verifyOTP/APP_MA";

const defaultState = {
  isLoading: true,
  initSuccess: null,
  maintenance: { status: false, message: null },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APP_MARK_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case APP_INIT_SUCCESS:
      return {
        ...state,
        initSuccess: true,
        isLoading: false,
      };
    case APP_INIT_FAIL:
      return {
        ...state,
        initSuccess: false,
        isLoading: false,
      };
    case APP_MA: {
      return {
        ...state,
        maintenance: { status: true, message: action.payload },
      };
    }
    default:
      return state;
  }
}

export const verifyOTP = (data) => async (dispatch) => {
  try {
    const token = _.get(data, "token", "");
    const otp = _.get(data, "otp", "");
    const email = _.get(data, "email", "");

    const bodyParameters = {
      token,
      otp,
      email,
    };
    const response = await mainAxios.post(
      "/api/verify_otp",
      bodyParameters
      // config
    );
    if (response.status === 200) {
      // console.log({response});
      await LocalStorageService.setToken(response.data);
      await LocalStorageService.setUsername(email);
      await setAuthorizationToken(LocalStorageService.getAccessToken());
      await setAuthorizationToken2(LocalStorageService.getIaccessToken());

      dispatch({ type: APP_INIT_SUCCESS });
      dispatch(authFetch());
      return response;
    }
  } catch (e) {
    dispatch({ type: APP_INIT_FAIL });
  }
};
