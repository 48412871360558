import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useQuery, useQueryClient } from "react-query";
import api from "../../../apis";
import { getChip } from "../../../data/hr03Status";
import Hr03Stepper from "../../templates/Hr03Stepper";
import Hr03AccountForm, { mapToInit } from "./Hr03AccountForm";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function Hr03VerifyAccountView({ itemId, handleCancel }) {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    data: item,
  } = useQuery(
    ["hr03", itemId],
    () => api.get(`/api/hr_reports/${itemId}`).then((res) => res.data),
    { staleTime: 60 * 1000 }
  );

  const [submitting, setSubmitting] = useState(false);
  const handleConfirm = () => {
    setSubmitting(true);
    api
      .put(`/api/hr_reports/${itemId}/sent_to_bh`)
      .then(() => {
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .then(() => {
        queryClient.invalidateQueries(["hr03", itemId]);
      })
      .catch(({ response }) => {
        handleCancel({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {});
  };

  const renderContent = () => {
    if (isLoading)
      return (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      );

    if (isError)
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
        </Alert>
      );

    return (
      <>
        <Form
          // validate={validate}
          readOnly
          onSubmit={() => undefined}
          component={Hr03AccountForm}
          initialValues={mapToInit(item)}
        />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item md={12} lg={4}>
            <LoadingButton
              type="button"
              variant="contained"
              startIcon={<Check />}
              onClick={() => handleConfirm()}
              loading={submitting}
              loadingPosition="start"
            >
              ยืนยันข้อมูล
            </LoadingButton>
          </Grid>
          <Grid item md={12} lg={8}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  handleCancel();
                }}
                disabled={submitting}
              >
                ปิด
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  };

  const modalStyle = {
    ...masterModalStyle,
    height: item ? "100%" : undefined,
    width: item ? "80%" : undefined,
  };

  return (
    <Box sx={modalStyle}>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="div">
          ตรวจสอบบัญชี HR03 (ID: {itemId})
        </Typography>
        {!isLoading && item?.status && getChip(item.status)}
      </Stack>
      <Paper sx={{ p: 2, mb: 3 }}>
        {item?.status && (
          <Hr03Stepper status={item.status} note={item.status_note} />
        )}
      </Paper>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        {renderContent()}
      </Paper>
    </Box>
  );
}
