import PropTypes from "prop-types";
import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Grid,
  Link,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../apis";
import {
  dataReportClearResult,
  dataReportFilter,
  dataReportLoading,
  fetchDataReport,
} from "../../../ducks/report";
import DataReportSearchForm from "./DataReportSearchForm";
import { visuallyHidden } from "@mui/utils";

export default function DataReport() {
  const dispatch = useDispatch();
  const viewerOrgCode = useSelector(
    (state) => state.auth.user?.viewer_org_code
  );

  const initialValues = {
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    request_type: "all",
    report_type: 1,
    condition_type: 1,
    org_code: viewerOrgCode,
  };

  const [formState, setFormState] = useState(initialValues);

  const fetchIdRef = React.useRef(0);
  const forceReloadRef = React.useRef(0);
  const didRequest = React.useRef(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [orgName, SetOrgName] = useState();
  const [conditionTypeName, SetConditionTypeName] = useState();

  const [orgNameLabel, SetOrgNameLabel] = useState();
  const [conditionTypeNameLabel, SetConditionTypeNameLabel] = useState();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [search, setSearch] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(dataReportClearResult());
      dispatch(dataReportFilter({}));
    };
  }, [dispatch]);

  const report_static_request_data = useSelector(
    (state) => state.auth.user?.permissions?.report_static_request_data ?? []
  );

  const data = useSelector((state) => state?.report?.data_report);

  const loading = useSelector((state) => state.report.metaDataReport?.loading);
  const filter = useSelector((state) => state.report.metaDataReport?.filter);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  React.useEffect(() => {
    dispatch(dataReportLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(
      fetchDataReport(
        didRequest.current ? filter : initialValues,
        fetchId,
        fetchIdRef
      )
    )
      .catch(({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.error(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(dataReportClearResult());
        dispatch(dataReportLoading(false));
      })
      .finally(() => {
        didRequest.current = true;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, forceReloadRef.current]);

  const onSearchFormSubmit = (data) => {
    setFormState(data);
    orgNameLabel && SetOrgName(orgNameLabel);
    conditionTypeNameLabel && SetConditionTypeName(conditionTypeNameLabel);
    setSearch("");
    dispatch(dataReportFilter(data));
  };

  const handleDownload = (exportType) => {
    setLoadingPDF(true);
    setPdfDownloading(true);
    api
      .get(`/api/reports/list`, {
        params: {
          start_date: formState.start_date,
          end_date: formState.end_date,
          report_type: formState.report_type,
          org_code: formState.org_code,
          condition_type: formState.condition_type,
          export: exportType,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setPdfDownloading(false);
      });
  };

  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {data &&
            data?.header?.map((headCell) => (
              <TableCell
                key={headCell.key}
                align={headCell.key === "name" ? "left" : "center"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.key ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : "asc"}
                  onClick={createSortHandler(headCell.key)}
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const visibleRows = React.useMemo(
    () =>
      [...(data && data?.data ? data?.data : [])].sort(
        getComparator(order, orderBy)
      ),
    [data, getComparator, order, orderBy]
  );

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">สถิติการขอข้อมูล</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            onSubmit={onSearchFormSubmit}
            component={DataReportSearchForm}
            loading={loading}
            onReset={() => {
              dispatch(dataReportFilter(initialValues));
              setSearch("");
            }}
            initialValues={initialValues}
            SetOrgNameLabel={SetOrgNameLabel}
            SetConditionTypeNameLabel={SetConditionTypeNameLabel}
            SetOrgName={SetOrgName}
            SetConditionTypeName={SetConditionTypeName}
            search={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: 700,
            width: "100%",
          }}
        >
          <Grid container spacing={2} mb={2} alignItems={"center"}>
            <Grid item md={9}>
              <Typography>
                สถิติการขอข้อมูล {orgName ? orgName : "สำนักงานตำรวจแห่งชาติ"} -{" "}
                {conditionTypeName ? conditionTypeName : "จำนวนหมาย"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              {report_static_request_data.includes("download") && (
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    startIcon={<Download />}
                    loadingPosition="start"
                    loading={pdfDownloading}
                    disabled={loadingPDF}
                    aria-controls={openDowload ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDowload ? "true" : undefined}
                    onClick={handleClickDowload}
                  >
                    ดาวน์โหลด
                  </LoadingButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElDowload}
                    open={openDowload}
                    onClose={handleCloseDownload}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleDownload("csv");
                      }}
                    >
                      CSV
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Grid>
          </Grid>

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow
                    hover
                    key={Math.random()}
                    style={{
                      height: 53,
                    }}
                  >
                    {data?.header?.map((el, i) => (
                      <TableCell
                        key={row.code + index + el.key + i}
                        component="td"
                        scope="row"
                        padding="none"
                        align={el.key === "name" ? "left" : "center"}
                      >
                        {el.key === "name" &&
                        (row.type === "BH" || row.type === "BK") ? (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              setSearch(row.code);
                            }}
                          >
                            {row[el.key]}
                          </Link>
                        ) : (
                          <Typography variant="p">
                            {el.key === "name" ||
                            el.key === "total" ||
                            el.key === "total_approve" ||
                            el.key === "total_request"
                              ? row[el.key]
                              : row[el.key]?.total}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow
                  hover
                  key="99999"
                  style={{
                    height: 53,
                  }}
                >
                  {data?.header?.map((el, i) => (
                    <TableCell
                      component="td"
                      scope="row"
                      padding="none"
                      align={el.key === "name" ? "left" : "center"}
                      key={i + el.key}
                    >
                      <Typography variant="p" fontWeight={900} fontSize={14}>
                        {el.key !== "name" ? data?.footer[el.key] : "รวม"}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
