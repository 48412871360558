import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect } from "react";
import FieldDatePicker from "../form-fields/FieldDatePicker";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";
import thaiIdValidator from "../../helpers/ThaiIdValidator";

export default function ChPasswordForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประชาชน"
            controlProps={{ fullWidth: true }}
            inputProps={{ placeholder: "เลขบัตรประชาชน(card_id)" }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="laser_code"
            label="เลขหลังบัตรประชาชน"
            controlProps={{ fullWidth: true }}
            inputProps={{
              placeholder: "เลขหลังบัตรประชาชน(LaserCode)",
            }}
            // required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="สกุล"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDatePicker
            name="birthday"
            label="วันเกิด"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy",
              disableFuture: true,
              openTo: "year",
              views: ["year", "month", "day"],
              allowSameDateSelection: true,
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="password"
            label="รหัสผ่านปัจจุบัน"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="new_password"
            label="รหัสผ่านใหม่"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            readOnly={readOnly}
            helperText={
              "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="confirm_password"
            label="ยืนยันรหัสผ่านใหม่"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            readOnly={readOnly}
            helperText={
              "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น"
            }
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validateRules = {
  card_id: Joi.string()
  .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
  .required()
  .messages({
    "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
  }),
  laser_code: Joi.string()
    .pattern(/^[A-Za-z]{2}[0-9]{10}$/)
    .messages({
      "string.pattern.base": `หมายเลขหลังบัตรประจำตัวประชาชน 12 หลัก (พิมพ์ติดกันไม่ต้องใส่ "-") เช่น JT1234567890 หลักที่ 1-2 เป็นตัวอักษรภาษาอังกฤษ และหลักที่ 3-12 เป็นตัวเลข ดูหมายเลขได้จากตำเเหน่งดังรูป`,
      // 'any.required': 'กรุณาระบุเลขหลังบัตรประชาชน'
    })
    .allow(null, ""),
  fname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  birthday: Joi.string()
    .messages({
      "string.pattern.base": "กรุณากรอกวันที่ให้ถูกต้อง เช่น 21/07/1996",
      "any.required": "กรุณาระบุวันเกิด",
    })
    .required(),
  password: Joi.string()
    .messages({
      "any.required": "กรุณาระบุรหัสผ่าน",
    })
    .required(),
  new_password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัสผ่านใหม่",
    })
    .required(),
  confirm_password: Joi.any()
    .valid(Joi.ref("new_password"))
    .messages({
      "any.only": "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น",
      "any.required": "กรุณาระบุรหัสผ่านใหม่",
    })
    .required(),
};

// const schema = Joi.object(validateRules).error(validationMsg());
const schema = Joi.object(validateRules);

export const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};
