import { Mail } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import SubmitBtn from "../../../SubmitBtn";

import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldsignatureFileDropZone from "../../../form-fields/FieldsignatureFileDropZone";
import SignaturePreview from "../../requests/request-forms/SignaturePreview";
import UrgentRequest from ".//UrgentRequest";
import AccountRequest from "./AccountRequest";
import AccountRequestR from "./AccountRequestR";
import AmountRequest from "./AmountRequest";
import AtmRequest from "./AtmRequest";
import CctvRequest from "./CctvRequest";
import ConfirmCfrRequest from "./ConfirmCfrRequest";
import StatementRequest from "./StatementRequest";
import { useDispatch } from "react-redux";
import { preAccountsActions } from "../../../../ducks/requestAccountsPickerField";

export default function RequestNewForm({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
  setLetter,
  setLetterH,
  setLetterA,
  setLetterB,
  setLetterQ,
  setLetterR,
  setLetterD,
  setLetterE,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // const [open, setOpen] = React.useState(false);
  const [requestTypes, setRequestTypes] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccount, setBankAccount] = useState(null);

  const [chooseH, setChooseH] = React.useState(false);
  const [choose, setChoose] = React.useState(false);
  const [chooseA, setChooseA] = React.useState(false);
  const [chooseB, setChooseB] = React.useState(false);
  const [chooseQ, setChooseQ] = React.useState(false);
  const [chooseR, setChooseR] = React.useState(false);
  const [chooseD, setChooseD] = React.useState(false);
  const [chooseE, setChooseE] = React.useState(false);

  setLetter(choose);
  setLetterH(chooseH);
  setLetterA(chooseA);
  setLetterB(chooseB);
  setLetterQ(chooseQ);
  setLetterR(chooseR);
  setLetterD(chooseD);
  setLetterE(chooseE);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const bankOptions = useMemo(() => {
    const items = bankAccounts.map((item) => {
      return {
        value: item.bank?.id,
        label: `${item.bank?.name} (${item.bank?.short_name})`,
      };
    });
    return _.uniqBy(items, "value");
  }, [bankAccounts]);

  const sortBankAccounts = useMemo(() => {
    const bank = bankAccounts.filter((item) => item.bank_id === values.bank_id);
    const banks = bank.map((item) => {
      return {
        value: item.id,
        label: `${item.bank?.short_name} : ${item.number} : ${item.name}`,
      };
    });
    return banks;
  }, [bankAccounts, values.bank_id]);

  const filteredBankAccounts = useMemo(() => {
    return bankAccounts.filter((item) => item.bank_id === values.bank_id);
  }, [bankAccounts, values.bank_id]);

  const sortShowBank = useMemo(() => {
    return filteredBankAccounts.filter(
      (item) => item.id === values.bank_account
    );
  }, [filteredBankAccounts, values.bank_account]);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      if (value === "8") {
        setChooseH(true);
      } else if (value === "1") {
        setChoose(true);
      } else if (value === "2") {
        setChooseA(true);
      } else if (value === "3") {
        setChooseB(true);
      } else if (value === "5") {
        setChooseQ(true);
      } else if (value === "4") {
        setChooseR(true);
      } else if (value === "6") {
        setChooseD(true);
      } else if (value === "7") {
        setChooseE(true);
      } else {
      }

      api
        .get(`/api/cases/${caseId}/bank_accounts_for_request`, {
          params: { request_type_id: value },
        })
        .then(({ data }) => {
          setBankAccounts(data.rows);
        })
        .catch(() => {
          SetToastInfo({
            type: "error",
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลหมายเบื้องต้น",
          });
        })
        .finally(() => {});
    } else {
      if (value === "8") {
        setChooseH(false);
      } else if (value === "1") {
        setChoose(false);
      } else if (value === "2") {
        setChooseA(false);
      } else if (value === "3") {
        setChooseB(false);
      } else if (value === "5") {
        setChooseQ(false);
      } else if (value === "4") {
        setChooseR(false);
      } else if (value === "6") {
        setChooseD(false);
      } else if (value === "7") {
        setChooseE(false);
      } else {
      }
    }
  };

  const handleBankAccountChange = (value) => {
    // const { value } = e.target;
    setBankAccount(value);

    let data = {
      sortBankAccounts,
      select: value,
    }

    dispatch(preAccountsActions(data));
    
  };

  // useEffect(() => {
  //   if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
  //     setOpen(true);
  //   }
  // }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  useEffect(() => {
    api.get(`/api/request/types?object_type=bank&status=1`).then(({ data }) => {
      setRequestTypes(data);
    });
    api.get(`/api/cases/${caseId}/bank_accounts`).then(({ data }) => {
      setBankAccounts(data.rows);
    });
  }, [caseId]);

  useEffect(() => {
    console.log("values.bank_id", values?.request_type_2);
  }, [values]);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Snackbar
          open={toastInfo ? true : false}
          autoHideDuration={6000}
          onClose={handleToastInfoClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {toastInfo?.type && (
            <Alert
              onClose={handleToastInfoClose}
              severity={toastInfo?.type || "warning"}
              sx={{ width: "100%" }}
            >
              {toastInfo?.message || ""}
            </Alert>
          )}
        </Snackbar>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <FieldSelect
                name="bank_id"
                label="ธนาคาร"
                options={bankOptions}
                readOnly={readOnly}
                required
                controlProp={{ sx: { width: "100%" } }}
              />
              <FieldSelect
                name="bank_account"
                label="บัญชีธนาคาร"
                options={sortBankAccounts}
                readOnly={readOnly}
                required
                controlProp={{ sx: { width: "100%" } }}
                onChange={handleBankAccountChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="center"></Divider>
          </Grid>
        </Grid>
        <Grid item ml={2} mt={2} justifyContent="rigth">
          <Grid item xs={12}>
            <Typography variant="subtitle1">เลือกประเภทคำขอ</Typography>
          </Grid>
          <Grid item xs={12}>
            {requestTypes.map((v, i) => {
              return (
                <>
                  <FormGroup key={i}>
                    <FormControlLabel
                      name="request_type_id"
                      control={<Checkbox disabled={bankAccount === null} />}
                      value={v.value}
                      label={v.label}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  {chooseH && v.value === 8 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <ConfirmCfrRequest
                          readOnly={readOnly}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {choose && v.value === 1 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <UrgentRequest
                          readOnly={readOnly}
                          bankAccounts={sortShowBank}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {chooseA && v.value === 2 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <AccountRequest
                          readOnly={readOnly}
                          bankAccounts={sortShowBank}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {chooseB && v.value === 3 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <StatementRequest
                          readOnly={readOnly}
                          bankAccounts={sortShowBank}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {chooseQ && v.value === 5 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <AmountRequest
                          readOnly={readOnly}
                          bankAccounts={sortShowBank}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {chooseR && v.value === 4 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <AccountRequestR
                          readOnly={readOnly}
                          bankAccounts={sortShowBank}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                  {chooseD && v.value === 6 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <AtmRequest readOnly={readOnly} hiddenUserForm={true} />
                      </Grid>
                    </>
                  )}
                  {chooseE && v.value === 7 && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <CctvRequest
                          readOnly={readOnly}
                          hiddenUserForm={true}
                        />
                      </Grid>
                    </>
                  )}
                </>
              );
            })}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider textAlign="center" sx={{ mt: 2 }}>
                ข้อมูลพนักงานสอบสวน
              </Divider>
            </Grid>
            <Grid item xs={12} md={2}>
              <FieldInput
                name="rank"
                label="ยศ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="fname"
                label="ชื่อ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="lname"
                label="สกุล"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="job_title"
                label="ตำแหน่ง"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldAsyncAutoComplete
                name="policestation_org_code"
                label="หน่วยงาน"
                required
                controlProp={{ fullWidth: true }}
                readOnly
                basePath="/api/policestations"
                // itemFilter={(row) =>
                //   !row.ORG_CODE?.startsWith("0") &&
                //   !row.ORG_CODE?.startsWith("1")
                // }
                itemFormat={(row) => {
                  return { value: row.ORG_CODE, label: row.ORG_ABBR };
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="email"
                label="email"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="pol_tel"
                label="โทรศัพท์"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <FieldInput
                name="address"
                label="ที่อยู่"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
              />
            </Grid>
            <Grid container item xs={12} justifyContent={"center"}>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">ลายเซ็น</Typography>
                {readOnly ? (
                  <SignaturePreview name="profile_signature" />
                ) : (
                  <FieldsignatureFileDropZone
                    name="profile_signature"
                    accept="image/jpeg, image/png, image/gif"
                    placeholder={
                      <>
                        Drag 'n' drop some files here, or click to select files{" "}
                        <br />
                        (*.jpg,*.gif,*.png) only
                      </>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} margin={3}>
            <Divider textAlign="center"></Divider>
          </Grid>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              {!readOnly && (
                <SubmitBtn
                  variant="contained"
                  startIcon={<Mail />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  สร้างคำขอ
                </SubmitBtn>
              )}
              <Button
                type="button"
                variant="outlined"
                disabled={submitting}
                onClick={() => {
                  navigate(`/cases/${caseId}/mgt`);
                }}
              >
                {readOnly ? "ปิด" : "ยกเลิก"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

// const renderRequest = (readOnly, request_type_id, bankAccounts) => {
//   switch (request_type_id) {
//     case 1:
//       return <UrgentRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
//     case 2:
//       return <AccountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
//     case 3:
//       return (
//         <StatementRequest readOnly={readOnly} bankAccounts={bankAccounts} />
//       );
//     case 4:
//       return <AccountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
//     case 5:
//       return <AmountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
//     case 6:
//       return <AtmRequest readOnly={readOnly} />;
//     case 7:
//       return <CctvRequest readOnly={readOnly} />;
//     // case 8:
//     //   return <IncompleteAccountRequest readOnly={readOnly} />;
//     // case 9:
//     //   return (
//     //     <StatementRequest readOnly={readOnly} bankAccounts={bankAccounts} />
//     //   );
//     case 8:
//       return <ConfirmCfrRequest readOnly={readOnly} />;
//     default:
//       return (
//         <Grid item xs={12}>
//           not support yet
//         </Grid>
//       );
//   }
// };

export const mapToInit = (item) => {
  return item;
};
