import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  AccountCircle,
  Assignment,
  AutoAwesomeMotion,
  Dashboard,
  Description,
  EditNotifications,
  FeaturedPlayList,
  FindInPage,
  Foundation,
  Inbox,
  Key,
  Logout,
  ManageAccounts,
  MonitorHeart,
  NoteAdd,
  Notifications,
  NotificationsNone,
  NotificationsOff,
  PeopleAlt,
  Person,
  PersonOff,
  RequestPage,
  Rule,
  Score,
  Search,
  Storage,
  TextSnippet,
  UploadFile,
  Widgets,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MenuIcon from "@mui/icons-material/Menu";
import PublishIcon from "@mui/icons-material/Publish";
import {
  Avatar,
  Badge,
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MuiLink from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import { th } from "date-fns/locale";

import * as React from "react";
import { MdChevronRight, MdMenuOpen } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import api from "../../apis";
import { authLogout } from "../../ducks/auth";
import { nameComposer } from "../../helpers/stringFormater";
import LineConnectDialog from "../pages/LineConnectDialog";
import CollapseMenuItemBotton from "../templates/CollapseMenuItemBotton";
import CaseMainMenuBotton from "./CaseMainMenuBotton";
import "./main.css";
import MenuItemBotton from "./MenuItemBotton";

const drawerWidth = 310;
const drawerWidthMoblie = 100;

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

function Copyright(props) {
  const [normCount, setNormCount] = React.useState(0);
  const navigate = useNavigate();

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        onClick={() => {
          setNormCount((prev) => ++prev);
          if (normCount > 6) {
            setNormCount(0);
            navigate("/admin/file-normalize");
          }
        }}
        {...props}
      >
        CCIB-DEV@
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        <MuiLink
          rel="noopener"
          target="_blank"
          href="https://sites.google.com/view/pctpolice/home"
        >
          คู่มือการใช้งาน
        </MuiLink>
        , ติดต่อผู้ดูแลระบบทาง{" "}
        <MuiLink rel="noopener" target="_blank" href="https://lin.ee/u3MNdBI">
          Line Official Account
        </MuiLink>
      </Typography>
    </>
  );
}

function Main() {
  const dashboard = useSelector(
    (state) => state.auth.user?.permissions?.dashboard ?? []
  );
  const monitor = useSelector(
    (state) => state.auth.user?.permissions?.monitor ?? []
  );
  const cases = useSelector(
    (state) => state.auth.user?.permissions?.cases ?? []
  );
  const assign_case = useSelector(
    (state) => state.auth.user?.permissions?.assign_case ?? []
  );
  const requests = useSelector(
    (state) => state.auth.user?.permissions?.requests ?? []
  );
  const saving_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.saving_account_HR03 ?? []
  );
  const verify_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.verify_account_HR03 ?? []
  );
  const request_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.request_account_HR03 ?? []
  );
  const line_notification = useSelector(
    (state) => state.auth.user?.permissions?.line_notification ?? []
  );
  const extorg_request_list = useSelector(
    (state) => state.auth.user?.permissions?.extorg_request_list ?? []
  );
  const extorg_template_upload = useSelector(
    (state) => state.auth.user?.permissions?.extorg_template_upload ?? []
  );
  const search_account = useSelector(
    (state) => state.auth.user?.permissions?.search_account ?? []
  );
  // const organize_request = useSelector(
  //   (state) => state.auth.user?.permissions?.organize_request ?? []
  // );
  const report_requests = useSelector(
    (state) => state.auth.user?.permissions?.report_requests ?? []
  );
  const report_HR03_data = useSelector(
    (state) => state.auth.user?.permissions?.report_HR03_data ?? []
  );
  const manage_new_request = useSelector(
    (state) => state.auth.user?.permissions?.manage_new_request ?? []
  );
  const new_request = useSelector(
    (state) => state.auth.user?.permissions?.new_request ?? []
  );
  const approved_request = useSelector(
    (state) => state.auth.user?.permissions?.approved_request ?? []
  );
  const approved_request_group = useSelector(
    (state) => state.auth.user?.permissions?.approved_request_group ?? []
  );
  const template_group = useSelector(
    (state) => state.auth.user?.permissions?.template_group ?? []
  );
  const all_request = useSelector(
    (state) => state.auth.user?.permissions?.all_request ?? []
  );
  const template_upload = useSelector(
    (state) => state.auth.user?.permissions?.template_upload ?? []
  );
  const HR03_filter = useSelector(
    (state) => state.auth.user?.permissions?.HR03_filter ?? []
  );
  const upload_suspicious = useSelector(
    (state) => state.auth.user?.permissions?.upload_suspicious ?? []
  );
  const file_normalization = useSelector(
    (state) => state.auth.user?.permissions?.file_normalization ?? []
  );
  const setting_police_station = useSelector(
    (state) => state.auth.user?.permissions?.setting_police_station ?? []
  );
  const setting_request = useSelector(
    (state) => state.auth.user?.permissions?.setting_request ?? []
  );
  const setting_agency = useSelector(
    (state) => state.auth.user?.permissions?.setting_agency ?? []
  );
  const setting_user = useSelector(
    (state) => state.auth.user?.permissions?.setting_user ?? []
  );
  const setting_permission = useSelector(
    (state) => state.auth.user?.permissions?.setting_permission ?? []
  );
  const setting_config = useSelector(
    (state) => state.auth.user?.permissions?.setting_config ?? []
  );
  const setting_external = useSelector(
    (state) => state.auth.user?.permissions?.setting_external ?? []
  );
  const transaction_log = useSelector(
    (state) => state.auth.user?.permissions?.transaction_log ?? []
  );
  const master_log = useSelector(
    (state) => state.auth.user?.permissions?.master_log ?? []
  );
  const permission_log = useSelector(
    (state) => state.auth.user?.permissions?.permission_log ?? []
  );
  const user_log = useSelector(
    (state) => state.auth.user?.permissions?.user_log ?? []
  );
  const notify_log = useSelector(
    (state) => state.auth.user?.permissions?.notify_log ?? []
  );
  // const hr03_log = useSelector(
  //   (state) => state.auth.user?.permissions?.hr03_log ?? []
  // );

  const report_static_request_data = useSelector(
    (state) => state.auth.user?.permissions?.report_static_request_data ?? []
  );

  const report_static_org_external = useSelector(
    (state) => state.auth.user?.permissions?.report_static_org_external ?? []
  );

  const report_static_assign_case = useSelector(
    (state) => state.auth.user?.permissions?.report_static_assign_case ?? []
  );

  const isInternal = useSelector(
    (state) => state.auth.user?.isInternal ?? false
  );

  const notify_token = useSelector(
    (state) => state.auth.user?.notify_token ?? null
  );

  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const { data: casesassign } = useQuery(
    "caseAssignCount",
    () =>
      api
        .get(`/api/assign`, {
          params: {
            page_size: 1,
            page: 0,
          },
        })
        .then((res) => res.data.total),
    {
      staleTime: 60 * 1000,
    }
  );

  useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      flexShrink: 0,
      width: drawerOpen ? drawerWidth : drawerWidthMoblie,
      "&::-webkit-scrollbar": { display: "none" },
    },
    drawerPaper: {
      width: drawerOpen ? drawerWidth : drawerWidthMoblie,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }));

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const onClickDrawer = () => setDrawerOpen(!drawerOpen);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const [notification, setNotification] = React.useState(null);
  const handleNoti = (event) => {
    setNotification(event.currentTarget);
    notiRefetch();
  };
  const handleCloseNoti = () => {
    setNotification(null);
  };

  const [lineOpen, setLineOpen] = React.useState(false);
  const handleLineConnect = () => {
    setLineOpen(true);
  };

  const {
    rank,
    fname,
    lname,
    email,
    org,
    line_notify_link: lineNotiLink,
    bank,
  } = useSelector((state) => state.auth.user);
  const orgabbr = org?.ORG_ABBR;

  const displayName = nameComposer(rank, fname, lname);
  let location = useLocation();
  let pathname = location?.pathname;

  const signOut = React.useCallback(() => {
    dispatch(authLogout());
  }, [dispatch]);

  const buttonMenuSX = (pl) => {
    if (!drawerOpen)
      return {
        textAlign: "center",
        justifyContent: "center",
        flex: 1,
      };
    else {
      return {
        margin: 1,
        borderRadius: 999,
        "&.Mui-selected": {
          backgroundColor: "#0077FF",
          color: "white",
        },
      };
    }
  };

  const buttonRoundedSX = (path) => {
    if (!drawerOpen) {
      if (path === pathname)
        return {
          color: "white",
          backgroundColor: "#0077FF",
          padding: 0.5,
          height: 32,
          width: 32,
          borderRadius: 999,
        };
    } else {
      if (path === pathname) return { color: "white" };
    }
  };
  const { data: messages, refetch: notiRefetch } = useQuery(["messages"], () =>
    api
      .get(`/api/notification`, {
        params: {
          page_size: 10,
          page: 0,
          type: "list",
        },
      })
      .then((res) => res.data)
  );

  return (
    <div className={classes.root}>
      <LineConnectDialog
        open={lineOpen}
        link={lineNotiLink}
        onClose={() => setLineOpen(false)}
      />
      <CssBaseline />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar + " relative"}
        style={{ background: "#0664CF" }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <div
            className={clsx({
              "absolute top-0 h-[64px] border-[0.5px] hidden lg:block": true,
              "left-[305px]": drawerOpen,
              "left-[99px]": !drawerOpen,
            })}
          />

          <button
            onClick={onClickDrawer}
            className={clsx({
              "absolute top-0 h-[64px] w-[64px] hidden lg:flex justify-center items-center": true,
              "left-[305px]": drawerOpen,
              "left-[100px]": !drawerOpen,
            })}
          >
            <MdMenuOpen className="h-7 w-7" />
          </button>

          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/rtp-logo.png`}
            alt="Logo"
            height="48px"
          />

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginLeft: "6px",
              // ...(open && { display: "none" }),
            }}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, marginLeft: 2 }}
          >
            {drawerOpen && <span className="hidden lg:block">Bank Portal</span>}
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleNoti}
              color="inherit"
            >
              <Badge color="error" className="relative">
                {messages && messages.data.length > 0 && (
                  <div className="absolute right-0 top-0 bg-red-500 border-2 border-red-500 h-2 w-2 rounded-full animate-pulse" />
                )}
                <NotificationsNone />
              </Badge>
            </IconButton>
            <Menu
              id="menu-appbar-noti"
              anchorEl={notification}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(notification)}
              onClose={handleCloseNoti}
              PaperProps={{ sx: { width: "466.5px", height: "665px" } }}
              MenuListProps={{ sx: { minHeight: "100%", display: "flex" } }}
            >
              <CssBaseline />
              <Paper sx={{ width: "100%", minHeight: "100%" }} elevation={0}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  sx={{ p: 2, pb: 0 }}
                >
                  การแจ้งเตือน
                </Typography>
                <Divider />
                {messages && messages.data.length > 0 ? (
                  <List>
                    {messages.data.map(
                      ({
                        id,
                        created_at,
                        updated_at,
                        title,
                        content,
                        type,
                        type_name,
                        user_id,
                        status,
                      }) => (
                        <React.Fragment key={id}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar alt="Profile Picture">
                                <NotificationsNone />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${type_name} ${title}`}
                              secondary={formatDistanceToNow(
                                new Date(created_at),
                                { addSuffix: true, locale: th }
                              )}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </List>
                ) : (
                  <Stack
                    height="calc(100% - 48px)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <NotificationsOff
                      sx={{ fontSize: 40, color: "GrayText" }}
                    />
                    <Typography variant="h5" align="center" color={"GrayText"}>
                      ไม่มีแจ้งเตือน
                    </Typography>
                  </Stack>
                )}
              </Paper>
            </Menu>
          </div>

          <Typography>{email}</Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItemBotton
                component={MenuItem}
                to="/me"
                onClick={handleClose}
                exact
              >
                <ListItemIcon
                  className={
                    !drawerOpen ? "flex justify-center items-center" : ""
                  }
                >
                  <AccountCircle fontSize="small" />
                </ListItemIcon>
                <ListItemText>ข้อมูลผู้ใช้</ListItemText>
              </MenuItemBotton>
              <MenuItemBotton
                component={MenuItem}
                to="/me/chpassword"
                onClick={handleClose}
              >
                <ListItemIcon
                  className={
                    !drawerOpen ? "flex justify-center items-center" : ""
                  }
                >
                  <Key fontSize="small" />
                </ListItemIcon>
                <ListItemText>เปลี่ยนรหัสผ่าน</ListItemText>
              </MenuItemBotton>
              <MenuItem onClick={signOut}>
                <ListItemIcon
                  className={
                    !drawerOpen ? "flex justify-center items-center" : ""
                  }
                >
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>ออกจากระบบ</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <nav>
          {!open && (
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            ></Toolbar>
          )}

          {drawerOpen ? (
            <List sx={{ p: 0 }}>
              <div>
                <Box
                  sx={{
                    p: drawerOpen ? 2 : undefined,
                    display: "grid",
                    textDecoration: "none",
                    color: "inherit",
                    minWidth: drawerOpen ? drawerWidth : drawerWidthMoblie,
                  }}
                  component={Link}
                  to="/me"
                  className="relative"
                >
                  {/* <Grid item xs={12} md={4}></Grid> */}
                  <div className="flex justify-center items-center">
                    <div className="border-[2px] rounded-full shadow-md relative">
                      <div className="absolute right-0 top-2.5 bg-green-400 border-2 border-green-400  h-2.5 w-2.5 rounded-full" />
                      <img src="/images/cop-icon.png" className="m-1" alt="" />
                    </div>
                  </div>
                  {/* <Grid item xs={12} md={4}></Grid> */}
                  <Grid item container sx={{ textAlign: "center" }}>
                    {drawerOpen && (
                      <Grid item xs={12}>
                        <Stack
                          spacing={0.5}
                          sx={{
                            minWidth: 0,
                          }}
                        >
                          <p className="text-[16px]">{displayName}</p>
                          <p className="text-sm text-[#696969]">
                            {!isInternal ? bank?.name || "" : orgabbr}
                          </p>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </div>
            </List>
          ) : (
            <Box
              component={Link}
              to="/me"
              className="flex justify-center items-center"
            >
              <div className="border-[2px] rounded-full shadow-md mt-3 relative">
                <div className="absolute right-0 top-2.5 bg-green-400 border-2 border-green-400 h-2.5 w-2.5 rounded-full" />
                <img
                  src="/images/cop-icon.png"
                  className="m-1 h-16 w-16"
                  alt=""
                />
              </div>
            </Box>
          )}
          {drawerOpen && <Divider />}
          {(dashboard.includes("list") || monitor.includes("list")) && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  ข้อมูลภาพรวม
                </p>

                {dashboard.includes("list") && (
                  <MenuItemBotton to="/dashboard" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Score sx={buttonRoundedSX("/dashboard")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Summary Dashboard"
                        primaryTypographyProps={{
                          fontSize: "15px",
                          // color: pathname === "/dashboard" && "white",
                        }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {monitor.includes("list") && (
                  <MenuItemBotton to="/monitor" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <MonitorHeart sx={buttonRoundedSX("/monitor")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="System Monitoring"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}
          {(cases.includes("list") ||
            assign_case.includes("list") ||
            requests.includes("list") ||
            saving_account_HR03.includes("list") ||
            verify_account_HR03.includes("list") ||
            request_account_HR03.includes("list") ||
            line_notification.includes("list")) && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  ข้อมูลของฉัน
                </p>

                {cases.includes("list") && (
                  <CaseMainMenuBotton
                    drawerOpen={drawerOpen}
                    pathname={pathname}
                  />
                )}
                {assign_case.includes("list") && (
                  <MenuItemBotton to="/casesassign" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <NoteAdd sx={buttonRoundedSX("/casesassign")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="มอบหมาย Case"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                    {drawerOpen && (
                      <Stack spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label={casesassign ?? 0}
                            color="error"
                            size="small"
                            sx={{
                              height: "auto",
                              "& .MuiChip-label": {
                                display: "block",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </MenuItemBotton>
                )}

                {/* {cases.includes("list") && (
                <MenuItemBotton to="/caseswaitapprove" exact sx={buttonMenuSX}>
                  <ListItemIcon
                    className={
                      !drawerOpen ? "flex justify-center items-center" : ""
                    }
                  >
                    <AssignmentTurnedIn
                      sx={buttonRoundedSX("/caseswaitapprove")}
                    />
                  </ListItemIcon>
                  {drawerOpen && (
                    <ListItemText
                      primary="Case รออนุมัติ"
                      primaryTypographyProps={{ fontSize: "15px" }}
                    />
                  )}
                  {drawerOpen && (
                    <Stack spacing={1} alignItems="center">
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label="22"
                          color="error"
                          size="small"
                          sx={{
                            height: "auto",
                            "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                            },
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}
                </MenuItemBotton>
              )} */}

                {requests.includes("list") && (
                  <MenuItemBotton to="requests" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <RequestPage sx={buttonRoundedSX("/requests")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="คำขอทั้งหมด"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {saving_account_HR03.includes("list") && (
                  <MenuItemBotton to="/hr03" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <PersonOff sx={buttonRoundedSX("/hr03")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="บันทึกบัญชี HR03"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {/* <RequireRoles roleIds={[2, 4]}>
                <MenuItemBotton to="/hr03/mgt" exact>
                  <ListItemIcon
                    className={
                      !drawerOpen ? "flex justify-center items-center":""
                    }
                  >
                    <Rule />
                  </ListItemIcon>
                  <ListItemText primary="ตรวจสอบข้อมูล HR03" />
                </MenuItemBotton>
              </RequireRoles> */}
                {verify_account_HR03.includes("list") && (
                  <MenuItemBotton to="/hr03/mgt" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Rule sx={buttonRoundedSX("/hr03/mgt")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ตรวจสอบข้อมูล HR03"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {request_account_HR03.includes("list") && (
                  <MenuItemBotton
                    to="/hr03/request-active"
                    exact
                    sx={buttonMenuSX}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <FileCopyIcon
                        sx={buttonRoundedSX("/hr03/request-active")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ขอเปิดการใช้งานบัญชี HR03"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {line_notification.includes("list") && (
                  <MenuItemBotton
                    to=""
                    exact
                    sx={buttonMenuSX}
                    onClick={handleLineConnect}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/line-icon.png`}
                        alt="Logo"
                        height="23px"
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary={
                          notify_token
                            ? "เปลี่ยนการผูกแจ้งเตือนทาง Line"
                            : "รับแจ้งเตือนทาง Line"
                        }
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {/* {!lineNotiToken && line_notification.includes("list") && (
                  <ListItemButton
                    onClick={handleLineConnect}
                    sx={
                      !drawerOpen
                        ? {
                            textAlign: "center",
                            justifyContent: "center",
                            flex: 1,
                          }
                        : undefined
                    }
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/line-icon.png`}
                        alt="Logo"
                        height="23px"
                       
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="รับแจ้งเตือนทาง Line"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </ListItemButton>
                )} */}
              </div>
            </List>
          )}

          {(extorg_request_list.includes("list") ||
            extorg_template_upload.includes("list") ||
            extorg_template_upload.includes("upload") ||
            !isInternal) && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  หน่วยงานภายนอก
                </p>
                {(extorg_request_list.includes("list") || !isInternal) && (
                  <MenuItemBotton to="/requestletter" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Foundation sx={buttonRoundedSX("/requestletter")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="รายการหมายคำขอ"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {(extorg_request_list.includes("upload") ||
                  extorg_template_upload.includes("list") ||
                  extorg_template_upload.includes("upload") ||
                  !isInternal) && (
                  <MenuItemBotton
                    to="/external/responses/upload"
                    sx={buttonMenuSX}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <UploadFile
                        sx={buttonRoundedSX("/external/responses/upload")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Template Upload"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}

          {search_account.includes("list") /*||
            organize_request.includes("list")*/ && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  ข้อมูลระบบ
                </p>

                {search_account.includes("list") && (
                  <MenuItemBotton to="/acc-search" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <FindInPage sx={buttonRoundedSX("/acc-search")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ค้นหาบัญชี"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {/* 
              <RequireRoles roleIds={[2, 5]}>
                <MenuItemBotton to="/req-search">
                  <ListItemIcon
                    className={
                      !drawerOpen ? "flex justify-center items-center":""
                    }
                  >
                    <Pageview />
                  </ListItemIcon>
                  <ListItemText primary="คำขอของหน่วยงาน" />
                </MenuItemBotton>
              </RequireRoles> */}
                {/* {organize_request.includes("list") && (
                  <MenuItemBotton to="/req-search" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Pageview sx={buttonRoundedSX("/req-search")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="คำขอของหน่วยงาน"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )} */}
              </div>
            </List>
          )}

          {(report_static_org_external.includes("list") ||
            report_static_request_data.includes("list") ||
            report_requests.includes("list") ||
            report_HR03_data.includes("list")) && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  ข้อมูลรายงาน
                </p>
                {report_static_request_data.includes("list") && (
                  <MenuItemBotton to="/report/data" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Description sx={buttonRoundedSX("/report/data")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="สถิติการขอข้อมูล"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {report_static_org_external.includes("list") && (
                  <MenuItemBotton to="/report/ext-data" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Description sx={buttonRoundedSX("/report/ext-data")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="สถิตข้อมูลหน่วยงานภายนอก"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {report_static_assign_case.includes("list") && (
                  <MenuItemBotton to="/report/assign" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Description sx={buttonRoundedSX("/report/assign")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="สถิติการมอบหมายเคส"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {report_requests.includes("list") && (
                  <MenuItemBotton to="/report/request" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Description sx={buttonRoundedSX("/report/request")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="รายการคำขอ"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {report_HR03_data.includes("list") && (
                  <MenuItemBotton to="/report/hr03" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <TextSnippet sx={buttonRoundedSX("/report/hr03")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="รายงานข้อมูล HR03"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}

          {((manage_new_request.includes("list") &&
            new_request.includes("list")) ||
            approved_request.includes("list") ||
            approved_request_group.includes("list") ||
            template_group.includes("list") ||
            all_request.includes("list") ||
            all_request.includes("list") ||
            new_request.includes("list") ||
            approved_request.includes("list") ||
            approved_request_group.includes("list") ||
            template_group.includes("list") ||
            all_request.includes("list") ||
            template_upload.includes("list") ||
            HR03_filter.includes("list") ||
            upload_suspicious.includes("list") ||
            file_normalization.includes("list")) && (
            <List>
              <div>
                <>
                  <p
                    className={clsx({
                      "text-xs ml-0 text-center": !drawerOpen,
                      "font-medium text-base text-left ml-3": drawerOpen,
                    })}
                  >
                    Admin
                  </p>
                  {manage_new_request.includes("list") &&
                    (drawerOpen ? (
                      <CollapseMenuItemBotton
                        to="/admin/request-mgt"
                        icon={<Inbox />}
                        text={"จัดการคำขอ"}
                        // sx={buttonMenuSX}
                      >
                        {new_request.includes("list") && (
                          <MenuItemBotton
                            to="/admin/request-mgt/sent_to_contact_person"
                            sx={() => buttonMenuSX(4)}
                          >
                            <ListItemIcon>
                              <Assignment
                                sx={buttonRoundedSX(
                                  "/admin/request-mgt/sent_to_contact_person"
                                )}
                              />
                            </ListItemIcon>
                            {drawerOpen && (
                              <ListItemText
                                primary="คำขอเข้าใหม่"
                                primaryTypographyProps={{ fontSize: "15px" }}
                              />
                            )}
                          </MenuItemBotton>
                        )}

                        {approved_request.includes("list") && (
                          <MenuItemBotton
                            to="/admin/request-mgt/checked"
                            exact
                            sx={() => buttonMenuSX(4)}
                          >
                            <ListItemIcon>
                              <Dashboard
                                sx={buttonRoundedSX(
                                  "/admin/request-mgt/checked"
                                )}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="คำขอตรวจแล้ว"
                              primaryTypographyProps={{ fontSize: "15px" }}
                            />
                          </MenuItemBotton>
                        )}

                        {approved_request_group.includes("list") && (
                          <MenuItemBotton
                            to="/admin/request-mgt/checked-sum"
                            sx={() => buttonMenuSX(4)}
                          >
                            <ListItemIcon>
                              <AutoAwesomeMotion
                                sx={buttonRoundedSX(
                                  "/admin/request-mgt/checked-sum"
                                )}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="คำขอตรวจแล้ว(กลุ่ม)"
                              primaryTypographyProps={{ fontSize: "15px" }}
                            />
                          </MenuItemBotton>
                        )}

                        {template_group.includes("list") && (
                          <MenuItemBotton
                            to="/admin/request-mgt/sum-req"
                            sx={() => buttonMenuSX(4)}
                          >
                            <ListItemIcon>
                              <Widgets
                                sx={buttonRoundedSX(
                                  "/admin/request-mgt/sum-req"
                                )}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="template มัดรวม"
                              primaryTypographyProps={{ fontSize: "15px" }}
                            />
                          </MenuItemBotton>
                        )}

                        {all_request.includes("list") && (
                          <MenuItemBotton
                            to="/admin/request-mgt"
                            exact
                            sx={() => buttonMenuSX(4)}
                          >
                            <ListItemIcon>
                              <Search
                                sx={buttonRoundedSX("/admin/request-mgt")}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="คำขอทั้งหมด"
                              primaryTypographyProps={{ fontSize: "15px" }}
                            />
                          </MenuItemBotton>
                        )}
                      </CollapseMenuItemBotton>
                    ) : (
                      <div>
                        {all_request.includes("list") && (
                          <MenuItemBotton
                            aria-label="account of current user"
                            aria-controls="menu-leftbar"
                            aria-haspopup="true"
                            onClick={onClickMenu}
                            to="/admin/request-mgt"
                            sx={
                              !drawerOpen && {
                                textAlign: "center",
                                justifyContent: "center",
                                flex: 1,
                              }
                            }
                          >
                            <ListItemIcon
                              className={
                                !drawerOpen &&
                                "flex justify-center items-center relative"
                              }
                            >
                              <Inbox />

                              <MdChevronRight className="h-5 w-5 absolute right-0" />
                            </ListItemIcon>
                          </MenuItemBotton>
                        )}
                        <Menu
                          id="menu-leftbar"
                          anchorEl={anchorElMenu}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          open={Boolean(anchorElMenu)}
                          onClose={handleCloseMenu}
                        >
                          {new_request.includes("list") && (
                            <MenuItemBotton
                              to="/admin/request-mgt/sent_to_contact_person"
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <Assignment
                                  sx={buttonRoundedSX(
                                    "/admin/request-mgt/sent_to_contact_person"
                                  )}
                                />
                              </ListItemIcon>
                            </MenuItemBotton>
                          )}

                          {approved_request.includes("list") && (
                            <MenuItemBotton
                              to="/admin/request-mgt/checked"
                              exact
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <Dashboard
                                  sx={buttonRoundedSX(
                                    "/admin/request-mgt/checked"
                                  )}
                                />
                              </ListItemIcon>
                            </MenuItemBotton>
                          )}

                          {approved_request_group.includes("list") && (
                            <MenuItemBotton
                              to="/admin/request-mgt/checked-sum"
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <AutoAwesomeMotion
                                  sx={buttonRoundedSX(
                                    "/admin/request-mgt/checked-sum"
                                  )}
                                />
                              </ListItemIcon>
                            </MenuItemBotton>
                          )}

                          {template_group.includes("list") && (
                            <MenuItemBotton
                              to="/admin/request-mgt/sum-req"
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <Widgets
                                  sx={buttonRoundedSX(
                                    "/admin/request-mgt/sum-req"
                                  )}
                                />
                              </ListItemIcon>
                            </MenuItemBotton>
                          )}

                          {all_request.includes("list") && (
                            <MenuItemBotton
                              to="/admin/request-mgt"
                              exact
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon>
                                <Search
                                  sx={buttonRoundedSX("/admin/request-mgt")}
                                />
                              </ListItemIcon>
                            </MenuItemBotton>
                          )}
                        </Menu>
                      </div>
                    ))}
                </>

                {template_upload.includes("list") && (
                  <MenuItemBotton
                    to="/admin/responses/upload"
                    sx={buttonMenuSX}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <UploadFile
                        sx={buttonRoundedSX("/admin/responses/upload")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Template Upload"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {HR03_filter.includes("list") && (
                  <MenuItemBotton to="/HR03Meeting" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <FeaturedPlayList sx={buttonRoundedSX("/HR03Meeting")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="คัดกรองรายการ HR03"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {upload_suspicious.includes("list") && (
                  <MenuItemBotton to="/UploadSuspicious" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <PublishIcon sx={buttonRoundedSX("/UploadSuspicious")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Upload เหตุอันควรสงสัย"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {file_normalization.includes("list") && (
                  <MenuItemBotton to="/admin/file-normalize" sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <TextSnippet
                        sx={buttonRoundedSX("/admin/file-normalize")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="File Normalization"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}

          {(setting_police_station.includes("list") ||
            setting_request.includes("list") ||
            setting_agency.includes("list")) && (
            <List>
              <div>
                <>
                  <p
                    className={clsx({
                      "text-xs ml-0 text-center": !drawerOpen,
                      "font-medium text-base text-left ml-3": drawerOpen,
                    })}
                  >
                    MasterData
                  </p>
                  {setting_police_station.includes("list") && (
                    <MenuItemBotton to="/settingagency" exact sx={buttonMenuSX}>
                      <ListItemIcon
                        className={
                          !drawerOpen ? "flex justify-center items-center" : ""
                        }
                      >
                        <ApartmentIcon sx={buttonRoundedSX("/settingagency")} />
                      </ListItemIcon>
                      {drawerOpen && (
                        <ListItemText
                          primary="หน่วยงานตำรวจ"
                          primaryTypographyProps={{ fontSize: "15px" }}
                        />
                      )}
                    </MenuItemBotton>
                  )}
                </>

                {setting_request.includes("list") && (
                  <MenuItemBotton to="/settingrequest" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <ArticleIcon sx={buttonRoundedSX("/settingrequest")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ประเภทหมายคำขอ"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {setting_agency.includes("list") && (
                  <MenuItemBotton to="/settingchannel" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <ContentPasteGoIcon
                        sx={buttonRoundedSX("/settingchannel")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="หน่วยงานภายนอก"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}

          {(setting_user.includes("list") ||
            setting_permission.includes("list") ||
            setting_config.includes("list") ||
            setting_external.includes("list")) && (
            <List>
              <div>
                <>
                  <p
                    className={clsx({
                      "text-xs ml-0 text-center": !drawerOpen,
                      "font-medium text-base text-left ml-3": drawerOpen,
                    })}
                  >
                    ข้อมูลการตั้งค่า
                  </p>
                  {setting_user.includes("list") && (
                    <MenuItemBotton to="/settinguser" exact sx={buttonMenuSX}>
                      <ListItemIcon
                        className={
                          !drawerOpen ? "flex justify-center items-center" : ""
                        }
                      >
                        <Person sx={buttonRoundedSX("/settinguser")} />
                      </ListItemIcon>
                      {drawerOpen && (
                        <ListItemText
                          primary="ข้อมูลผู้ใช้งานระบบ"
                          primaryTypographyProps={{ fontSize: "15px" }}
                        />
                      )}
                    </MenuItemBotton>
                  )}
                </>

                {setting_permission.includes("list") && (
                  <MenuItemBotton
                    to="/settingpermission"
                    exact
                    sx={buttonMenuSX}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <ManageAccounts
                        sx={buttonRoundedSX("/settingpermission")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ข้อมูลสิทธิ์การใช้งาน"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {setting_config.includes("list") && (
                  <MenuItemBotton to="/setting" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <EditNotifications sx={buttonRoundedSX("/setting")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ตั้งค่าการใช้งาน"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
                {/* {
                <MenuItemBotton to="/organizationchart" exact sx={buttonMenuSX}>
                  <ListItemIcon
                    className={
                      !drawerOpen ? "flex justify-center items-center" : ""
                    }
                  >
                    <AccountTreeIcon
                      sx={buttonRoundedSX("/organizationchart")}
                    />
                  </ListItemIcon>
                  {drawerOpen && (
                    <ListItemText
                      primary="OrganizationChart"
                      primaryTypographyProps={{ fontSize: "15px" }}
                    />
                  )}
                </MenuItemBotton>
              } */}
                {setting_external.includes("list") && (
                  <MenuItemBotton
                    to="/settinguserExternal"
                    exact
                    sx={buttonMenuSX}
                  >
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <PeopleAlt sx={buttonRoundedSX("/settinguserExternal")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="ข้อมูลผู้ใช้งานหน่วยงานภายนอก"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}

          {(transaction_log.includes("list") ||
            master_log.includes("list") ||
            permission_log.includes("list") ||
            user_log.includes("list")) && (
            <List>
              <div>
                <p
                  className={clsx({
                    "text-xs ml-0 text-center": !drawerOpen,
                    "font-medium text-base text-left ml-3": drawerOpen,
                  })}
                >
                  AuditLog Data
                </p>
                {transaction_log.includes("list") && (
                  <MenuItemBotton to="/transactionlog" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Description sx={buttonRoundedSX("/transactionlog")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Transaction Log"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {master_log.includes("list") && (
                  <MenuItemBotton to="/masterlog" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Storage sx={buttonRoundedSX("/masterlog")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Master Log"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {permission_log.includes("list") && (
                  <MenuItemBotton to="/permissionlog" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <CalendarViewDayIcon
                        sx={buttonRoundedSX("/permissionlog")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Permission Log"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {user_log.includes("list") && (
                  <MenuItemBotton to="/userlog" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <AdminPanelSettingsIcon
                        sx={buttonRoundedSX("/userlog")}
                      />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="User Log"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}

                {notify_log.includes("list") && (
                  <MenuItemBotton to="/notifylog" exact sx={buttonMenuSX}>
                    <ListItemIcon
                      className={
                        !drawerOpen ? "flex justify-center items-center" : ""
                      }
                    >
                      <Notifications sx={buttonRoundedSX("/notifylog")} />
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        primary="Notify Log"
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    )}
                  </MenuItemBotton>
                )}
              </div>
            </List>
          )}
        </nav>
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          // height: "auto",
          overflow: "auto",
        }}
        id="main-container"
        className={classes.content + " z-10"}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </div>
  );
}

export default Main;
